(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

//Functions
//Run JQuery scripts
$(function () {

	//Prevent default anchor actions
	$(".prev-default").click(function (event) {
		event.preventDefault();
	});
	//Show the select options
	$(".select > .select-placeholder").on("click", function (event) {
		var x = event.clientX;
		var y = event.clientY;
		$(this).next(".select-menu-wrapper").css({ top: y, left: x }).fadeIn(400, "easeOutCirc");
	}); //Show the select options

	//Change the selects placeholder text with what the user chose
	$(".select-menu-wrapper .option").on("click", function () {
		var optionSelected = $(this).text(),
		    placeholder = $(this).parents(".select").find(".select-value > span:first-child");
		$(this).parents(".select-menu-wrapper").fadeOut(400, "easeInCirc");
		$(placeholder).text(optionSelected).addClass("active");
		$(this).parents(".select").find(".select-value").removeClass("select-placeholder");
	});

	//Show/Hide the tertiary nav dropdowns
	$(".tert-nav li a").click(function (event) {
		event.preventDefault();
		$(".tert-nav__wrapper, .tert-nav__wrapper--messages").fadeOut(200, "easeOutExpo");
		$(this).siblings().fadeToggle(400, "easeOutExpo");
	}); //Show/Hide the tertiary nav dropdowns

	//Hide the tertiary nav dropdowns on mouse out
	$(".tert-nav > li").on("mouseleave", function () {
		$(this).find(".tert-nav__wrapper, .tert-nav__wrapper--messages").fadeOut(200, "easeOutExpo");
		$("#new-message-wrap").fadeOut(400, "easeInExpo");
	}); //Hide the tertiary nav dropdowns on mouse out

	//Add a new message
	$("#new-message").click(function () {
		$(this).parents(".tert-nav__wrapper--messages").find("#new-message-wrap").fadeIn(400, "easeOutExpo");
	}); //Add a new message

	//Close new message
	$("#close-new-message").click(function () {
		$(this).parents(".tert-nav__wrapper--messages").find("#new-message-wrap").fadeOut(400, "easeInExpo");
	}); //Close new message

	//Show a message thread
	$("#my-messages li").on("click", function () {
		$(".message-thread.thread-" + $(this).data('content')).fadeIn();
	}); //Show a message thread

	//Back to message from a message thread
	$(".back-to-messages").click(function () {
		$(this).parents(".message-thread").fadeOut(200, "easeOutExpo");
	}); //Back to message from a message thread

	//Toggle the active class to change the color of a gray button to light blue
	$(".toggle__btn--plain").on("click", ".btn--gray, .btn--green", function () {
		$(this).toggleClass("active");
	}); //toggle a button gray/blue

	//Show the edit/delete controls for comments
	$(".thumb-posts__comment__controls > .pulldown").click(function () {
		$(this).next().fadeToggle(400, "easeInOutExpo");
		$(this).find(".fa-chevron-down").toggleClass("rotate-180");
	}); //toggle the edit/delete comment controls

	$(".thumb-posts__comment__controls").on("mouseleave", function () {
		$(this).find(".actions").fadeOut(400, "easeInExpo");
		$(this).find(".fa-chevron-down").removeClass("rotate-180");
	}); //Hide the edit/delete comment controls on mouse out

	//Show a pulldown or pick list
	$(".pulldown-wrap").click(function () {
		$(this).find(".actions").fadeToggle(400, "easeInOutExpo");
		$(this).find(".fa-chevron-down").toggleClass("rotate-180");
	}); //Show a pulldown

	//Hide a pulldown or pick list
	$(".actions").on("mouseleave", function () {
		// $(this).fadeOut(400, "easeInOutExpo");
		// $(this).parents(".pulldown-wrap").find(".fa-chevron-down").removeClass("rotate-180");
	}); //Hide the pulldown on mouse out	

	//Toggle the Club updates and Members list
	$(".toggle__btn--members-list").click(function (event) {
		event.preventDefault();
		$(this).parents(".members-parent-wrap").find(".member-updates").fadeOut(200, "easeOutExpo", function () {
			$(this).next(".member-list").fadeIn(100, "easeInExpo");
		});
	}); //Member list
	$(".toggle__btn--club-updates").click(function (event) {
		event.preventDefault();
		$(this).parents(".members-parent-wrap").find(".member-list").fadeOut(200, "easeOutExpo", function () {
			$(this).prev(".member-updates").fadeIn(100, "easeInExpo");
		});
	}); //Club updates

	//My courses live search
	$("#my-course-live-search, .search-box").on("click", function () {
		$(".search-box__results").fadeIn(400, "easeInExpo");
	});
	$('.search-box__results li').each(function () {
		$(this).attr('data-search-term', $(this).text().toLowerCase());
	}); //Loop through each li, get the text of the li, convert it to lowercase and add it to a new attribute called data-search-term

	//Loop through each course and show the matches to the search string
	$("#my-course-live-search").on('keyup', function () {
		var searchTerm = $(this).val().toLowerCase();
		$('.search-box__results li').each(function () {
			if ($(this).filter('[data-search-term *= ' + searchTerm + ']').length > 0) {
				$(this).fadeIn(400, "easeOutExpo");
			} else {
				$(this).fadeOut(400, "easeInExpo");
			}
		});
	}); //Show/hide each loop
	//Show a pulldown or pick list that is in an overflow div (in the hero)
	$(".pulldown-wrap-overflow").click(function () {
		$(this).parents(".hero").find(".actions--overflow").fadeToggle(400, "easeInOutExpo");
		$(this).find(".fa-chevron-down").toggleClass("rotate-180");
		//Position the overflowing more menu
		var moreli = $("#more-menu-li").offset();
		$("#more-menu").offset({ top: moreli.top + 30, left: moreli.left });
	});
	//Hide live search when selecting a course and add it to the list
	$(".search-box__results li").click(function (event) {
		var selected = $(this).text();
		event.stopPropagation();
		$(".search-box__results").fadeOut(400, "easeOutExpo");
		// $(this).parents(".search-box").find("> input").val(selected);
		/*
  		$("#my-courses > ul").append(
  			'<li>' + '<div class=' + "flex--space-between" + '>' +
  				'<div>' +
  					'<p><strong>Recreation Park Golf Course</strong></p>' +
  					'<p>Long Beach</p>' +
  				'</div>' +
  				'<div>' +
  					'<i class="fa fa-home icon--gray" aria-hidden="true"></i>' +
  					'<i class="fa fa-times-circle icon--red" aria-hidden="true"></i>' +
  				'</div>' +
  			'</li>'
  		);
  */
	}); //Hide live search when selecting a course

	//Hide live search on mouseout
	$(".search-box").on("mouseleave", function () {
		$(this).find(".search-box__results").fadeOut(400, "easeOutExpo");
	}); //Hide live search on mouseout

	//Prevent clicking on the label jumping to the input
	$("label[for]").on("click", function (e) {
		var target = window[this.htmlFor];
		target.checked = !target.checked;
		e.preventDefault();
	}); //Prevent label scrollbar jump

	//Add a tag functionality
	$("#add-club-desc").keydown(function (event) {
		var str = $(this).val();
		if (event.which === 9 || event.which === 13) {
			$.get('/admin/addClubTag', { name: str }, function (data) {
				$("#add-club-desc").siblings(".toggle__btn--plain").append('<span class="btn--' + (data === str ? 'green' : 'error') + ' editable">' + data + '</span>');
				$("#add-club-desc").val(""); // clear textarea
			});
		}
	}); //Add a tag

	//Show the delete button when hovering a toggle button
	$(".toggle__btn--plain").on("mouseenter", ".editable", function () {
		$(this).append('<i class' + '="fa fa-times-circle icon--red editable--delete"' + 'aria-hidden="true">' + '</i>');
	}); //Show the delete button when hovering a toggle button

	//Remove the delete button when hovering a toggle button
	$(".toggle__btn--plain").on("mouseleave", ".editable", function () {
		$(this).find("i").remove();
	}); //Remove the delete button when hovering a toggle button

	//Delete a toggle button from the DOM
	$(".toggle__btn--plain").on("click", ".editable--delete", function () {
		$.get('/admin/deleteClubTag', { id: $(this).parent().data('content') }); //ajax to the delete method with this id
		$(this).parent().fadeOut(400, "easeOutExpo", function () {
			$(this).remove();
		});
	}); //Delete an editable toggle button

	//Remove a benefit from the about us edit mode screen
	$(".add-benefit.drag-and-drop-row").on("click", ".editable--delete", function () {
		$.get('/admin/club-benefits/delete/' + $(this).parents('li').data('content'), function (data) {
			console.log(data);
		});
		$(this).parents("li").fadeOut(400, "easeOutExpo", function () {
			$(this).remove();
		});
	}); //Remove a benefit

	//Add benefit button
	$(".add-benefit__new").click(function () {
		$('span#save-benefit').show();
		$(this).hide();
		$(this).siblings("ul").append('<li>' + '<div class=' + "flex--space-between flex--center-vertical" + '>' + '<div>' + '<input type="text" id="add-benefit-desc" name="name" placeholder="Type to add a benefit...">' + '</div>' + '<div>' + '<i class="fa fa-times-circle icon--red editable--delete" aria-hidden="true"></i>' + '</div>' + '</li>').focus();
	}); //Add benefit button
	$('span#save-benefit').on('click', function () {
		$(this).closest('form').submit();
		$('span#saved-benefit-btn').show();
	});
	//Add a page
	/*
 	$(".hero__add-page").click(function() {
 		$(".overlay").fadeIn(400, "easeOutExpo");
 		$(".modal.modal--add-page").fadeIn(500, "easeOutExpo");
 	});//Add a page
 */

	//Close modal window
	$(".modal > i:first-child").click(function () {
		$(this).parent().fadeOut(400, "easeInExpo");
		$(".overlay").fadeOut(500, "easeInExpo");
	}); //Close modal window

	//Show the event results
	$(".manage-events__results-btn").on("click", function (event) {
		event.preventDefault();
		$(this).parents(".manage-events__event-details").find(".manage-events__event-results.modal").fadeIn();
	}); //Show the events results

	//Close the event results on mouse out
	$(".manage-events__event-results.modal").on("mouseleave", function () {
		$(this).fadeOut();
	}); //Close the event results on mouse leave

	//Resizing the browser section
	//Show the club updates and member list when resizing over 768px
	$(window).resize(function () {
		//Position the overflowing more menu
		var moreli = $("#more-menu-li").offset();
		$("#more-menu").offset({ top: moreli.top + 30, left: moreli.left });
		if ($(window).width() >= 768) {
			$(".member-list, .member-updates").fadeIn(100, "easeInExpo");
		} //Show the club updates and member list

		if ($(window).width() <= 768) {
			$(".member-list").fadeOut(100, "easeOutExpo");
		} //Hide the member list
	}); //Resize the window
}); //Automatically run these jQuery scripts when the page loads

},{}]},{},[1]);
